export default {
  SET_CONFIG: 'SET_CONFIG',
  SET_PRODUCT_RESULTS: 'SET_PRODUCT_RESULTS',
  SET_PRODUCT_STATS: 'SET_PRODUCT_STATS',
  SET_THEME: 'SET_THEME',
  BETTING_ENABLED: 'BETTING_ENABLED',
  SOCKET: 'SOCKET',
  UPDATE_PLAYER_AUTH: 'UPDATE_PLAYER_AUTH',
  UPDATE_PLAYER_BALANCE: 'UPDATE_PLAYER_BALANCE',
  UPDATE_PLATFORM: 'UPDATE_PLATFORM',
  UPDATE_COMPANY_NAME: 'UPDATE_COMPANY_NAME',
  UPDATE_COMPANY_INFO: 'UPDATE_COMPANY_INFO',
  UPDATE_RULES: 'UPDATE_RULES',
  UPDATE_BALLS: 'UPDATE_BALLS',
  SET_BALLS: 'SET_BALLS',
  SET_ACTIVE_BETTING_TAB: 'SET_ACTIVE_BETTING_TAB',
  SET_COLOR_BET: 'SET_COLOR_BET',
  RESET_BALLS: 'RESET_BALLS',
  UPDATE_MOST_LEAST_DRAWN: 'UPDATE_MOST_LEAST_DRAWN',
  SET_LANGUAGE: 'SET_LANGUAGE',
  UPDATE_TRANSLATIONS: 'UPDATE_TRANSLATIONS',
  ADD_TO_TICKET: 'ADD_TO_TICKET',
  RESET_TICKET: 'RESET_TICKET',
  UPDATE_BETS_CONFIG: 'UPDATE_BETS_CONFIG',
  UPDATE_ROUND: 'UPDATE_ROUND',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  REFRESH_BETSLIP: 'REFRESH_BETSLIP',
  UPDATE_OUTCOME_COLORS: 'UPDATE_OUTCOME_COLORS',
  ENABLE_DEBUG_MODE: 'ENABLE_DEBUG_MODE',
  SET_DRAWN_COLOR: 'SET_DRAWN_COLOR',
  RESET_COLORS: 'RESET_COLORS',
  RESET_COLOR_BET: 'RESET_COLOR_BET',
  UPDATE_EVENTS: 'UPDATE_EVENTS',
  SET_APP_LOADED: 'SET_APP_LOADED',
  UPDATE_SKIN_ID: 'UPDATE_SKIN_ID',
  UPDATE_REAL: 'UPDATE_REAL',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_CLIENT_PLATFORM: 'UPDATE_CLIENT_PLATFORM',
  UPDATE_CLIENT_TYPE: 'UPDATE_CLIENT_TYPE',
  UPDATE_CURRENCY: 'UPDATE_CURRENCY',
  CHECK_PAYIN: 'CHECK_PAYIN',
  DISABLE_PAYIN_FROM_SOCKET: 'DISABLE_PAYIN_FROM_SOCKET',
  UPDATE_GAME: 'UPDATE_GAME',
  TEMP_TOKEN: 'TEMP_TOKEN',
  ALLOW_REQUEST: 'ALLOW_REQUEST',
  TOGGLE_TICKET_HISTORY: 'TOGGLE_TICKET_HISTORY',
  TICKET_HISTORY: 'TICKET_HISTORY',
  LAST_TICKETS: 'LAST_TICKETS',
  RESET_BET: 'RESET_BET',
  LOG_IN: 'LOG_IN',
  SET_CURRENT_SETTINGS: 'SET_CURRENT_SETTINGS',
  SET_POP: 'SET_POP',
  UPDATE_PLAYER_TOKEN: 'UPDATE_PLAYER_TOKEN',
  LOAD_PLUGINS: 'LOAD_PLUGINS',
  SET_PLUGIN_SNIPPETS: 'SET_PLUGIN_SNIPPETS',
  BETTING_DISABLED_EVENT: 'BETTING_DISABLED_EVENT',
  SET_MODAL: 'SET_MODAL',
  ADD_BETTING_TAB: 'ADD_BETTING_TAB',
  REMOVE_BETING_TAB: 'REMOVE_BETTING_TAB',
  SET_IS_MOBILE_DEVICE_LAYOUT: 'SET_IS_MOBILE_DEVICE_LAYOUT',
  SET_IS_TABLET_DEVICE_LAYOUT: 'SET_IS_TABLET_DEVICE_LAYOUT',
  SET_IS_DESKTOP_DEVICE_LAYOUT: 'SET_IS_DESKTOP_DEVICE_LAYOUT',
  SET_FULL_SCREEN_STATUS: 'SET_FULL_SCREEN_STATUS',
  ALL_BETTING_DISABLED: 'ALL_BETTING_DISABLED',
  SET_SHOW_TICKET_CANCELED_MODAL: 'SET_SHOW_TICKET_CANCELED_MODAL',
  SET_CANCELED_TICKET: 'SET_CANCELED_TICKET',
  SET_PAYIN_DISABLED_FROM_SOCKET: 'SET_PAYIN_DISABLED_FROM_SOCKET',
  SET_LAST_SELECTED_BET: 'SET_LAST_SELECTED_BET',
  SET_HIDE_LOADER: 'SET_HIDE_LOADER',
  SET_DRAWN_BALLS_STATE_ACTIVE: 'SET_DRAWN_BALLS_STATE_ACTIVE',
  SET_TICKET_PAYIN_IN_PROG: 'SET_TICKET_PAYIN_IN_PROG',
};
