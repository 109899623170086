// eslint-disable-next-line import/no-cycle
import store from '@/store';
class Bet {
  constructor(data) {
    this.betType = data.betType || null;
    this.color = data.color || null;
    this.colorName = '';
    this.type = data.type;
    this.betName = data.betName || '';
    this.id = 0;
    this.rounds = 1;
    this.roundNumber = 0;
    this.oddIndex = data.oddIndex;
    this.oddPosition = data.oddPosition;
    this.productVersion = '1.0';
    this.betDisplayName = '';
    this.numEvents = 1;
    this.title = [];
    this.subtitle = '';
    this.value = [];
    this.numbers = [];
    this.bettingTab = data.betTab;
    this.outcomeName = data.outcomeName;
    this.outcome = '';
    this.outcomes = [];
    this.odd = Number(data.odd);
    this.odds = Number(data.odd);
    this.maxPerBetFutureRounds = 1;

    this.helpers = {
      1: 'one',
      2: 'two',
      3: 'three',
    };

    this.betTypes = {
      0: {
        1: 5,
        2: 6,
        3: 7,
      },
      1: {
        1: 0,
        2: 3,
      },
    };

    this.configure(data);
    this.setBetColor();
    this.setBetName();
    this.setPositions();
    this.setTitle();
    this.setSubtitle();
    this.setRound();

    return this;
  }
  configure(data) {
    const { colorBet } = store.getters;

    if (this.type === 'numbers') {
      this.numbers = data.numbers;
      this.numbers.sort(this.sortNumbersDefault);
      this.betType = this.betTypes[this.bettingTab][this.numbers.length];

      if (colorBet && colorBet.betTab === this.bettingTab) {
        store.dispatch('resetColorBet');
      }
    } else if (this.type === 'special') {
      this.value = data.value;
      this.betType = data.betType;
    } else {
      this.value = data.value;

      store.dispatch('resetBalls');
    }
  }

  setBetColor() {
    if (this.color) {
      this.colorName = store.getters.outcomeColors[this.outcomeName];
    }
  }
  // eslint-disable-next-line class-methods-use-this
  sortNumbersDefault(a, b) {
    return a - b;
  }
  setBetName() {
    if (this.type === 'numbers' && !this.betName) {
      const trans = store.getters.translations;
      if (this.bettingTab === 0) {
        this.betName = `${
          trans[`ngs.nextsix.${this.helpers[this.numbers.length]}_in_next_six_bet`]
        }`;
      } else if (this.numbers.length === 1) {
        this.betName = trans['ngs.nextsix.next_ball_bet'];
      } else if (this.numbers.length === 2) {
        this.betName = trans['ngs.nextsix.next_2_any_bet'];
      }
    }
  }
  isNumberOnBetslip(number) {
    if (this.type === 'numbers') {
      return this.numbers.indexOf(number - 1) > -1;
    }

    return false;
  }
  setRound() {
    this.round = store.getters.round;
    this.roundNumber = store.getters.round;
    this.eventId = store.getters.round;
    this.roundInfo = store.getters.round;
  }
  setPositions() {
    const min = store.getters.drawnBalls.length + 1 || 1;
    let max = store.getters.drawnBalls.length + 6;

    if (this.bettingTab && this.numbers.length > 1) {
      max = store.getters.drawnBalls.length + 2;
    }

    if (max > 35) {
      max = 35;
    }

    if (this.bettingTab) {
      if (this.numbers.length === 1 || this.type === 'special') {
        this.ballPositions = `(${min}.)`;
      } else {
        this.ballPositions = `(${min}.-${max}.)`;
      }
    } else {
      this.ballPositions = `(${min}.-${max}.)`;
    }
  }
  setSubtitle() {
    this.setPositions();
    this.subtitle = `${this.betName}${this.ballPositions}`;
    if (this.numbers.length > 0) {
      this.outcome = this.numbers.join(', ');
    } else if (this.color) {
      this.outcome = this.outcomeName;
    } else {
      this.outcome = this.betName;
    }
    this.market = this.subtitle;
  }
  setTitle() {
    if (this.type === 'special') {
      let name = '';
      if (this.color) {
        name = this.colorLabel;
      } else {
        name = this.outcomeName;
      }

      this.title.push({
        active: false,
        color: '',
        colorId: '',
        id: 1,
        number: 0,
        title: name,
      });
    } else {
      for (let i = 0; i < this.numbers.length; i += 1) {
        this.title.push({
          active: false,
          color: '',
          colorId: '',
          id: 1,
          number: this.numbers[i] + 1,
          title: this.numbers[i] + 1,
        });
      }
    }
    //  this.market = this.title;
  }
}

export default Bet;
