// eslint-disable-next-line import/no-cycle
import { each } from 'lodash';
import store from '@/store';
// eslint-disable-next-line import/no-cycle
import Bet from '../utility/Bet';
import eventBus from '@nsftx/games-sdk-js/src/utility/eventBus';

const betHandler = {
  addToBetslip(data) {
    if (store.getters.activeBettingTab === 1) {
      each(store.state.balls, (ball) => {
        if (ball.tabs[0] === 0) {
          //  eslint-disable-next-line
          ball.tabs = [];
          //  eslint-disable-next-line
          //  ball.style.active = false;
        }
      });
    }
    if (store.getters.activeBettingTab === 0) {
      each(store.state.balls, (ball) => {
        if (ball.tabs[0] === 1) {
          //  eslint-disable-next-line
          ball.tabs = [];
          //  eslint-disable-next-line
          //  ball.style.active = false;
        }
      });
      store.state.colorBet = null;
    }
    if (store.state.lastSelectedBet) {
      each(store.state.balls, (ball) => {
        //  eslint-disable-next-line
        //  ball.tabs = [];
        //  eslint-disable-next-line
        //  ball.style.active = false;
        if (store.getters.lastSelectedBet.numbers) {
          each(store.getters.lastSelectedBet.numbers, (number) => {
            if (number === ball.ball) {
              //  eslint-disable-next-line
              ball.tabs = [store.getters.lastSelectedBet.betTab];
              //  eslint-disable-next-line
              //  ball.style.active = true;
            }
          });
        }
      });
    }
    const bet = new Bet(data);
    //  const isValid = !store.getters.payinDisabledFromSocket;
    const isValid = true;
    if (!store.state.lastSelectedBet) {
      return;
    }
    (async () => {
      await store.dispatch('gamesBetslip/addPreBet', { bet, valid: isValid });
      eventBus.$emit('formatBetslipInputValue');
    })();
  },
  formatTicketData(params) {
    let { bets } = params;

    for (let i = 0; i < bets[0].numbers.length; i += 1) {
      bets[0].numbers[i] += 1;
    }

    bets = bets.map((bet) => {
      let { value } = bet;

      if (bet.numbers.length >= 1) {
        if (bet.numbers.length > 1) {
          value = bet.numbers;
        } else {
          // eslint-disable-next-line prefer-destructuring
          value = bet.numbers[0];
        }
      }

      return {
        ballId: store.getters.drawnBalls.length + 1,
        // eslint-disable-next-line no-nested-ternary
        value,
        //  payin: bet.stake,
        type: bet.betType,
        productVersion: bet.productVersion,
        ballPositions: bet.ballPositions,
        subtitle: bet.subtitle,
      };
    });

    return {
      payin: parseFloat(Number(params.payin).toFixed(2)),
      bets,
    };
  },
};

export default betHandler;
