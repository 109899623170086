export default {
  config: (state) => state.config,
  theme: (state) => state.theme,
  totalRacers: (state) => state.totalRacers,
  bettingEnabled: (state) => state.bettingEnabled,
  drawnBalls: (state) => state.drawnBalls,
  odds: (state) => state.odds,
  lessThanSixBallsLeft: (state) => state.lessThanSixBallsLeft,
  isOneBallLeft: (state) => state.drawnBalls.length >= 34,
  numberOfSelectedBalls: (state) => (state.bet && state.bet.numbers ? state.bet.numbers.length : 0),
  companyName: (state) => state.company.name,
  companyInfo: (state) => state.company,
  platform: (state) => state.platform,
  token: (state) => state.player.auth.token,
  remainingBalls: (state) => state.remainingBalls,
  balls: (state) => state.balls,
  activeBettingTab: (state) => state.activeBettingTab,
  bettingTabs: (state) => state.bettingTabs,
  drawnMost: (state) => state.drawnMost,
  drawnLeast: (state) => state.drawnLeast,
  betsConfig: (state) => state.betsConfig,
  round: (state) => state.round,
  roundInfo: (state) => state.round,
  payinDisabledFromSocket: (state) => state.payinDisabledFromSocket,
  payinDisabled: (state) => state.payinDisabled,
  notifications: (state) => state.notifications,
  outcomeColors: (state) => state.outcomeColors,
  bet: (state) => state.bet,
  bets: (state) => state.ticket.bets,
  betsModel: (state) => state.betsModel,
  debugMode: (state) => state.debugMode,
  colorsDrawn: (state) => state.colorsDrawn,
  colorBet: (state) => state.colorBet,
  events: (state) => state.events,
  appLoaded: (state) => state.appLoaded,
  player: (state) => state.player,
  language: (state) => state.language,
  game: (state) => state.language,
  tempToken: (state) => state.tempToken,
  clientPlatform: (state) => state.clientPlatform,
  clientType: (state) => state.clientType,
  skinId: (state) => state.skinId,
  ticketHistoryData: (state) => state.ticketHistoryData,
  requestDisabled: (state) => state.requestDisabled,
  expandTicketHistory: (state) => state.expandTicketHistory,
  lastTicketsData: (state) => state.lastTicketsData,
  noRebet: (state) => state.noRebet,
  currentSettings: (state) => state.currentSettings,
  popTenants: (state) => state.popTenants,
  plugins: (state) => state.plugins,
  timeForRemoveNotification: (state) => state.timeForRemoveNotification,
  isModalOpen: (state) => state.isModalOpen,
  isIsonis: (state) => state.isonisList.indexOf(state.config.taxAuthority) > -1,
  numberColors: (state) => state.numberColors,
  isDesktop: (state) => state.isDesktop,
  isMobile: (state) => state.isMobile,
  isTablet: (state) => state.isTablet,
  resultsData: (state) => state.resultsData,
  statsData: (state) => state.stats,
  isFullScreen: (state) => state.isFullScreen,
  isBettingDisabled: (state) => state.isBettingDisabled,
  showTicketCanceledModal: (state) => state.showTicketCanceledModal,
  canceledTicket: (state) => state.canceledTicket,
  lastSelectedBet: (state) => state.lastSelectedBet,
  hideLoader: (state) => state.hideLoader,
  isBoostActive: (state) => state.config.ui.config?.boost?.enabled || false,
  isFreeBetMode: (state) => state.isFreeBetMode,
  isDrawnBallsStateActive: (state) => state.drawnBallsStateActive,
  isTicketPayinInProg: (state) => state.isTicketPayinInProg,
};
