import { uuid } from 'vue-uuid';
import { assign, merge, filter, isNil, each } from 'lodash';
import mutationTypes from './mutationTypes';

export default {
  [mutationTypes.SET_CONFIG](state, config) {
    state.config = config;
  },
  [mutationTypes.SET_PRODUCT_RESULTS](state, data) {
    state.resultsData = [];
    const formatedResults = [];
    each(data, (round) => {
      if (round.balls.length === 35) {
        formatedResults.push(round);
      }
    });
    state.resultsData = formatedResults;
  },
  [mutationTypes.SET_PRODUCT_STATS](state, statsData) {
    state.stats = {};
    state.stats = statsData;
  },
  [mutationTypes.SOCKET]() {},
  [mutationTypes.SET_THEME](state, theme) {
    state.theme = theme;
  },
  [mutationTypes.SET_LANGUAGE](state, lang) {
    state.language = lang;
  },
  [mutationTypes.BETTING_ENABLED](state, enabled) {
    state.BETTING_ENABLED = enabled;
  },
  [mutationTypes.UPDATE_PLATFORM](state, platform) {
    state.platform = platform;
  },
  [mutationTypes.UPDATE_GAME](state, game) {
    state.game = game;
  },
  [mutationTypes.TEMP_TOKEN](state, tempToken) {
    state.tempToken = tempToken;
  },
  [mutationTypes.UPDATE_COMPANY_NAME](state, name) {
    state.company.name = name;
  },
  [mutationTypes.UPDATE_COMPANY_INFO](state, data) {
    state.company.currency = data.currency;
    state.company.currencySign = data.currencySign;
    state.company.language = data.language;
    state.company.timezone = data.timezone;
  },
  [mutationTypes.UPDATE_RULES](state, rules) {
    state.rules = assign(state.rules, rules);
    state.minPayment = rules.minBetAmount.value;
    state.totalPayment = rules.minBetAmount.value;
  },
  [mutationTypes.UPDATE_TRANSLATIONS](state, translations) {
    state.translations = merge({}, state.translations, translations);
  },
  [mutationTypes.UPDATE_PLAYER_AUTH](state, data) {
    const { balance } = state.player;
    state.player.auth.token = data.secureToken || data.tempToken;
    state.player.id = data.playerId;
    balance.currency = data.currencyCode;
    if (data.balanceDetails) {
      balance.balanceAmt = data.balanceDetails[0].balanceAmt / balance.divide;
    }
  },

  [mutationTypes.LOG_IN](state) {
    state.player.loggedIn = true;
  },
  [mutationTypes.UPDATE_PLAYER_BALANCE](state, data) {
    const { balance } = state.player;
    if (!balance.time && data.balanceChangeTimestamp) {
      balance.time = data.balanceChangeTimestamp;
    }
    if (data.balanceChangeTimestamp >= balance.time) {
      state.player.balance.amount = data.balanceArray[0].balanceAmt / state.player.balance.divide;
      balance.balanceAmt = data.balanceArray[0].balanceAmt / balance.divide;
    }
  },
  [mutationTypes.SET_ACTIVE_BETTING_TAB](state, index) {
    state.activeBettingTab = index;

    for (let i = 0; i < state.balls.length; i += 1) {
      if (!state.balls[i].style) {
        state.balls[i].style = {};
      }

      state.balls[i].style.active = state.balls[i].tabs.indexOf(state.activeBettingTab) > -1;
    }

    if (state.balls.length < 49) {
      state.balls.push({
        fake: true,
        tabs: [],
      });
    } else {
      state.balls.splice(48, state.balls.length - 1);
    }
  },
  [mutationTypes.SET_BALLS](state) {
    state.balls = [];

    for (let ball = 1; ball < 50; ball += 1) {
      state.balls.push({
        ball,
        tabs: [],
        type: null,
        style: {
          clear: (ball - 1) % 8 === 0,
        },
      });
    }
  },
  [mutationTypes.UPDATE_BALLS](state, ball) {
    state.balls = state.balls.map((b) => {
      if (ball && ball.ball === b.ball) {
        // Update the style properties
        // eslint-disable-next-line
        b.style = {
          ...b.style,
          disabled: ball.style?.disabled || b.style?.disabled,
          active: ball.style?.active || b.style?.active,
        };

        // Update the tabs
        if (ball.tabs) {
          // eslint-disable-next-line
          b.tabs = ball.tabs;
        }

        // Handle the click event
        if (ball.click) {
          const tabIndex = b.tabs.indexOf(state.activeBettingTab);
          if (tabIndex > -1) {
            b.tabs.splice(tabIndex, 1);
          } else {
            b.tabs.push(state.activeBettingTab);
          }
        }

        // Update the type
        // eslint-disable-next-line
        b.type = !isNil(ball.type) ? ball.type : b.type;
      }

      // Ensure the ball has a style object
      if (!b.style) {
        // eslint-disable-next-line
        b.style = {};
      }

      // Set the active state based on the tabs
      // eslint-disable-next-line
      b.style.active = b.tabs.includes(state.activeBettingTab);

      return b;
    });

    // Maintain a minimum number of balls
    if (state.balls.length < 50) {
      state.balls.push({
        fake: true,
        tabs: [],
      });
    } else {
      state.balls = state.balls.slice(0, 49);
    }
  },
  [mutationTypes.RESET_BALLS](state) {
    state.balls.forEach((ball) => {
      const tabIndex = ball.tabs.indexOf(state.activeBettingTab);
      if (tabIndex > -1) {
        ball.tabs.splice(tabIndex, 1);
        if (ball.style) {
          // eslint-disable-next-line
          ball.style.active = false;
        }
      }
    });

    // Maintain a minimum number of balls
    if (state.balls.length < 50) {
      state.balls.push({ fake: true, tabs: [] });
    } else {
      state.balls.splice(48, state.balls.length - 48);
    }
  },

  [mutationTypes.RESET_COLOR_BET](state) {
    state.colorBet = null;
  },
  [mutationTypes.UPDATE_MOST_LEAST_DRAWN](state, data) {
    state.drawnMost = [];
    state.drawnLeast = [];
    state.drawnMost = data.drawnMostTimes;
    state.drawnLeast = data.drawnLeastTimes;
  },
  [mutationTypes.ADD_TO_TICKET](state, data) {
    state.ticket.bets = [];
    state.ticket.bets[0] = data;
    state.bet = data;
  },

  [mutationTypes.UPDATE_ROUND](state, round) {
    state.round = round;
  },
  [mutationTypes.UPDATE_BETS_CONFIG](state, data) {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i += 1) {
      const bet = data[keys[i]];
      const key = keys[i];

      if (key in state.betsConfig) {
        state.betsConfig[key].active = bet.value;
      }
    }
  },
  [mutationTypes.DISABLE_PAYIN_FROM_SOCKET](state, value) {
    state.payinDisabledFromSocket = value;
  },

  [mutationTypes.RESET_BET](state) {
    state.bet = {
      id: null,
      title: null,
      subtitle: null,
      odds: null,
      type: null,
      outcomes: [],
      stake: 1,
    };
  },
  [mutationTypes.CHECK_PAYIN](state, forcedValue) {
    const balance = state.player.balance.amount;
    const bets = state.ticket.bets.length;
    const balls = state.drawnBalls.length;

    let disabled = true;

    if (!forcedValue) {
      if (balance >= state.totalStake) {
        if (bets > 0 && balls < 35) {
          disabled = false;
        }
      } else {
        disabled = false;
      }

      if (state.payinDisabledFromSocket) {
        disabled = true;
      }
    } else {
      disabled = forcedValue;
    }

    state.payinDisabled = disabled;
  },

  [mutationTypes.UPDATE_NOTIFICATIONS](state, payload) {
    let { message } = payload;
    let { status } = payload;
    let icon = '';

    if (payload.code && payload.code.toString().length > 0) {
      status = 'rejected';
    }

    if (status.value && status.value.toLowerCase() === 'open') {
      status = 'success';
    }

    if (status.value && status.value.toLowerCase() === 'closed') {
      status = 'closed';
    }

    switch (status) {
      case 'info':
        icon = 'info';
        break;
      case 'rejected':
        icon = 'close';
        break;
      case 'resolving':
        icon = 'info';
        message = state.translations.general_ticket_resolving;
        break;
      case 'success':
        icon = 'check-a';
        message = state.translations.general_ticket_confirmed;
        break;
      case 'error':
        icon = 'close';
        message = message || state.translations.general_ticket_error;
        break;
      case 'loginFailed':
        icon = 'close';
        message = state.translations.general_ticket_login_message;
        break;
      case 'closed':
        icon = 'check-a';
        message = state.translations.general_ticket_canceled;
        break;
      default:
        message = 'Unknown notification';
        break;
    }

    if (payload.details && payload.details.fatal === 'yes') {
      state.errorOverlay = {
        message,
        active: true,
      };
    }

    const notification = payload;
    if (!notification.id) {
      notification.id = uuid.v4();
    }

    notification.message = message;
    notification.icon = icon;
    notification.status = status;
    state.notifications = [];
    state.notifications.push(notification);
    state.notifications = filter(state.notifications, (n) => n.id !== payload.requestUuid);
  },

  [mutationTypes.HIDE_NOTIFICATION](state, notification) {
    let filteredNotifications = [];
    const id = notification.requestUuid;
    if (id) {
      filteredNotifications = filter(state.notifications, (n) => n.requestUuid !== id);
      state.notifications = filteredNotifications;
    } else {
      const idx = state.notifications.indexOf(notification);
      state.notifications.splice(idx, 1);
    }
  },

  [mutationTypes.REFRESH_BETSLIP](state) {
    if (state.ticket.bets[0]) {
      state.ticket.bets[0].setSubtitle();
    }
  },

  [mutationTypes.UPDATE_OUTCOME_COLORS](state, data) {
    state.outcomeColors = data.value;
  },

  [mutationTypes.ENABLE_DEBUG_MODE](state) {
    state.debugMode = true;
  },

  [mutationTypes.SET_DRAWN_COLOR](state, ball) {
    state.colorsDrawn[Math.floor((ball - 1) / 8)] += 1;
  },
  [mutationTypes.SET_COLOR_BET](state, bet) {
    state.colorBet = bet;
  },
  [mutationTypes.RESET_COLORS](state) {
    const keys = Object.keys(state.colorsDrawn);

    for (let key = 0; key < keys.length; key += 1) {
      state.colorsDrawn[keys[key]] = 0;
    }
  },

  [mutationTypes.UPDATE_EVENTS](state, data) {
    state.events = data;

    state.events.sort((eventA, eventB) => eventB.eventTime - eventA.eventTime);

    state.events.splice(10, state.events.length - 1);

    if (state.events[0].balls?.length < 35) {
      state.events.splice(0, 1);
    }
  },
  [mutationTypes.SET_APP_LOADED](state, data) {
    state.appLoaded = data;
  },
  [mutationTypes.UPDATE_SKIN_ID](state, skinId) {
    state.skinId = skinId;
  },
  [mutationTypes.UPDATE_REAL](state, real) {
    state.real = real;
  },
  [mutationTypes.UPDATE_USERNAME](state, username) {
    state.player.username = username;
  },
  [mutationTypes.UPDATE_CLIENT_PLATFORM](state, clientPlatform) {
    state.clientPlatform = clientPlatform;
  },
  [mutationTypes.UPDATE_CLIENT_TYPE](state, clientType) {
    state.clientType = clientType;
  },
  [mutationTypes.LAST_TICKETS](state, data) {
    const newData = [];

    for (let i = 0; i < data.length; i += 1) {
      const ticket = data[i];

      for (let j = 0; j < ticket.bets.length; j += 1) {
        const val = ticket.bets[j].value;

        ticket.bets[j].value = '';
        ticket.bets[j].value = [val].join(',');
        ticket.bets[j].typeValue = ticket.bets[j].bet.title;

        if (ticket.bets[j].type === 9) {
          ticket.bets[j].value = ticket.bets[j].outcome.title;
        }
      }

      newData.push(ticket);
    }

    state.lastTicketsData = newData;
  },
  [mutationTypes.ALLOW_REQUEST](state, allow) {
    state.requestDisabled = allow;
  },
  [mutationTypes.TOGGLE_TICKET_HISTORY](state) {
    state.expandTicketHistory = !state.expandTicketHistory;
  },
  [mutationTypes.TICKET_HISTORY](state, data) {
    state.ticketHistoryData = data;
  },
  [mutationTypes.SET_POP](state, platformName) {
    state.platform = platformName;
  },
  [mutationTypes.UPDATE_PLAYER_TOKEN](state, token) {
    state.player.auth.token = token;
  },
  [mutationTypes.LOAD_PLUGINS](state, plugins) {
    state.plugins = plugins;
  },
  [mutationTypes.BETTING_DISABLED_EVENT](state, disabled) {
    state.payinDisabled = disabled;
  },
  [mutationTypes.SET_MODAL](state, show) {
    state.isModalOpen = show;
  },
  [mutationTypes.ADD_BETTING_TAB](state, tab) {
    state.bettingTabs.push(tab);
  },
  [mutationTypes.REMOVE_BETTING_TAB](state) {
    state.bettingTabs.pop();
  },
  [mutationTypes.SET_IS_MOBILE_DEVICE_LAYOUT](state, isMobileDeviceLayout) {
    state.isMobile = isMobileDeviceLayout;
  },
  [mutationTypes.SET_IS_TABLET_DEVICE_LAYOUT](state, isTabletDeviceLayout) {
    state.isTablet = isTabletDeviceLayout;
  },
  [mutationTypes.SET_IS_DESKTOP_DEVICE_LAYOUT](state, isDesktopDeviceLayout) {
    state.isDesktop = isDesktopDeviceLayout;
  },
  [mutationTypes.SET_FULL_SCREEN_STATUS](state, fullScreen) {
    state.isFullScreen = fullScreen;
  },
  [mutationTypes.ALL_BETTING_DISABLED](state, isBettingDisabled) {
    state.isBettingDisabled = isBettingDisabled;
  },
  [mutationTypes.SET_SHOW_TICKET_CANCELED_MODAL](state, show) {
    state.showTicketCanceledModal = show;
  },
  [mutationTypes.SET_CANCELED_TICKET](state, ticket) {
    state.canceledTicket = ticket;
  },
  [mutationTypes.SET_PAYIN_DISABLED_FROM_SOCKET](state, isDisabled) {
    state.payinDisabledFromSocket = isDisabled;
  },
  [mutationTypes.SET_LAST_SELECTED_BET](state, bet) {
    state.lastSelectedBet = bet;
  },
  [mutationTypes.SET_HIDE_LOADER](state, hide) {
    state.hideLoader = hide;
  },
  [mutationTypes.SET_DRAWN_BALLS_STATE_ACTIVE](state, isActive) {
    state.drawnBallsStateActive = isActive;
  },
  [mutationTypes.SET_TICKET_PAYIN_IN_PROG](state, isInProg) {
    state.isTicketPayinInProg = isInProg;
  },
};
