import { isArray, merge, omit } from 'lodash';
import mutationTypes from './mutationTypes';
import isMobile from 'ismobilejs';
import results from '@nsftx/games-sdk-js/src/api/results';
import statistics from '@nsftx/games-sdk-js/src/api/statistics';
import auth from '@nsftx/games-sdk-js/src/api/auth';

const getEventValue = (bet) => {
  if (bet.outcome.drawn && !isArray(bet.outcome.drawn)) {
    const value = [];
    value.push(bet.outcome.drawn);
    return value;
  }
  if (bet.outcome.drawn && isArray(bet.outcome.drawn)) {
    return bet.outcome.drawn;
  }
  return bet.eventValue || [];
};

export default {
  setConfig({ commit }, config) {
    commit(mutationTypes.SET_CONFIG, config);
  },
  getResults({ getters, dispatch }) {
    const count = 11;
    results.getResults(getters.config, null, count).then((resultsResponse) => {
      dispatch('setResults', resultsResponse.data);
    });
  },
  getStats({ getters, dispatch }) {
    statistics.getStatistics(getters.config).then((stats) => {
      dispatch('setStats', stats.data);
      dispatch('updateMostLeastDrawn', stats.data);
    });
  },
  setResults({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_RESULTS, data);
  },
  setStats({ commit }, data) {
    commit(mutationTypes.SET_PRODUCT_STATS, data);
  },
  setUserData({ dispatch }, payload) {
    let user = {};
    if (payload.auth) {
      user = {
        auth: omit(payload.auth, 'user'),
        profile: payload.auth.user,
      };
    } else {
      user = {
        auth: {
          token: payload.token,
        },
        profile: omit(payload, ['token', 'tokenType']),
      };
    }
    dispatch('user/updateUser', user, { root: true });
  },
  setUserBalance({ dispatch }, payload) {
    dispatch('user/setUserBalance', payload, { root: true });
  },
  setUserUuid({ commit }, userUuid) {
    commit(mutationTypes.SET_USER_UUID, userUuid);
  },
  setTheme({ commit }, theme) {
    commit(mutationTypes.SET_THEME, theme);
  },
  socket({ commit }, data) {
    commit(mutationTypes.SOCKET, data);
  },
  updateTranslations({ commit }, translations) {
    commit(mutationTypes.UPDATE_TRANSLATIONS, translations);
  },
  setLoggedInStatus({ commit }) {
    commit(mutationTypes.LOG_IN);
  },
  updatePlatform({ commit }, platform) {
    commit(mutationTypes.UPDATE_PLATFORM, platform);
  },
  updatePlayerAuth({ commit }, data) {
    commit(mutationTypes.UPDATE_PLAYER_AUTH, data);
  },
  updatePlayerBalance({ commit }, data) {
    commit(mutationTypes.UPDATE_PLAYER_BALANCE, data);
  },
  updateCompanyName({ commit }, data) {
    commit(mutationTypes.UPDATE_COMPANY_NAME, data);
  },
  updateCompanyInfo({ commit }, data) {
    commit(mutationTypes.UPDATE_COMPANY_INFO, data);
  },
  updateRules({ commit }, rules) {
    commit(mutationTypes.UPDATE_RULES, rules);
  },
  updateBalls({ commit }, data) {
    commit(mutationTypes.UPDATE_BALLS, data);
  },
  setBalls({ commit }, data) {
    commit(mutationTypes.SET_BALLS, data);
  },
  setActiveBettingTab({ commit }, tab) {
    commit(mutationTypes.SET_ACTIVE_BETTING_TAB, tab);
  },
  setColorBet({ commit }, bet) {
    commit(mutationTypes.SET_COLOR_BET, bet);
  },
  resetBalls({ commit }) {
    commit(mutationTypes.RESET_BALLS);
  },
  resetColorBet({ commit }) {
    commit(mutationTypes.RESET_COLOR_BET);
  },
  updateMostLeastDrawn({ commit }, data) {
    commit(mutationTypes.UPDATE_MOST_LEAST_DRAWN, data);
  },
  resetSelection({ dispatch }) {
    dispatch('emptyBetslip');
    dispatch('resetBalls');
  },
  setCurrentSettings({ commit }, tenant) {
    commit(mutationTypes.SET_CURRENT_SETTINGS, tenant);
  },
  checkPayin({ commit }, enabled) {
    commit(mutationTypes.CHECK_PAYIN, enabled);
  },
  disablePayinFromSocket({ commit }, value) {
    commit(mutationTypes.DISABLE_PAYIN_FROM_SOCKET, value);
  },
  resetBet({ commit }) {
    commit(mutationTypes.RESET_BET);
  },
  updateBet({ dispatch }, bet) {
    dispatch('emptyBetslip');
    dispatch('addDataToBetslip', bet);
    dispatch('addToTicket', bet);
  },
  resetTicket({ commit }) {
    commit(mutationTypes.RESET_TICKET);
  },
  updateBetsConfig({ commit }, bets) {
    commit(mutationTypes.UPDATE_BETS_CONFIG, bets);
  },
  updateRound({ commit }, round) {
    commit(mutationTypes.UPDATE_ROUND, round);
  },
  refreshBetslip({ commit }) {
    commit(mutationTypes.REFRESH_BETSLIP);
  },
  updateOutcomeColors({ commit }, data) {
    commit(mutationTypes.UPDATE_OUTCOME_COLORS, data);
  },
  enableDebugMode({ commit }) {
    commit(mutationTypes.ENABLE_DEBUG_MODE);
  },
  setDrawnColor({ commit }, data) {
    commit(mutationTypes.SET_DRAWN_COLOR, data);
  },
  resetColors({ commit }) {
    commit(mutationTypes.RESET_COLORS);
  },
  updateEvents({ commit }, data) {
    commit(mutationTypes.UPDATE_EVENTS, data);
  },
  setAppLoaded({ commit }, data) {
    commit(mutationTypes.SET_APP_LOADED, data);
  },
  updateSkinId({ commit }, data) {
    commit(mutationTypes.UPDATE_SKIN_ID, data);
  },
  updateReal({ commit }, data) {
    commit(mutationTypes.UPDATE_REAL, data);
  },
  updateUsername({ commit }, data) {
    commit(mutationTypes.UPDATE_USERNAME, data);
  },
  updateClientPlatform({ commit }, data) {
    commit(mutationTypes.UPDATE_CLIENT_PLATFORM, data);
  },
  updateClientType({ commit }, data) {
    commit(mutationTypes.UPDATE_CLIENT_TYPE, data);
  },
  updateGame({ commit }, data) {
    commit(mutationTypes.UPDATE_GAME, data);
  },
  setLanguage({ commit }, language) {
    commit(mutationTypes.SET_LANGUAGE, language);
  },
  setTempToken({ commit }, tempToken) {
    commit(mutationTypes.TEMP_TOKEN, tempToken);
  },
  allowRequest({ commit }, allow) {
    commit(mutationTypes.ALLOW_REQUEST, allow);
  },
  toggleTicketHistory({ commit }) {
    commit(mutationTypes.TOGGLE_TICKET_HISTORY);
  },
  setTicketDetailsData({ commit }, payload) {
    const { data } = payload;
    const newData = [];
    for (let i = 0; i < data.length; i += 1) {
      const ticket = data[i];

      for (let j = 0; j < ticket.bets.length; j += 1) {
        const val = ticket.bets[j].value;

        ticket.bets[j].value = '';
        ticket.bets[j].value = [val].join(',');
        ticket.bets[j].typeValue = ticket.bets[j].bet.title;

        if (ticket.bets[j].type === 9) {
          ticket.bets[j].value = ticket.bets[j].outcome.title;
        }
      }

      newData.push(ticket);
    }
    commit(payload.mutation, newData);
  },
  setPop({ commit }, platformName) {
    commit(mutationTypes.SET_POP, platformName);
  },
  updatePlayerToken({ commit }, token) {
    commit(mutationTypes.UPDATE_PLAYER_TOKEN, token);
  },
  loadPlugins({ commit }, plugins) {
    commit(mutationTypes.LOAD_PLUGINS, plugins);
  },
  bettingDisabledEvent({ commit }, disabled) {
    commit(mutationTypes.BETTING_DISABLED_EVENT, disabled);
  },
  setModal({ commit }, show) {
    commit(mutationTypes.SET_MODAL, show);
  },
  addBettingTab({ commit }, tab) {
    commit(mutationTypes.ADD_BETTING_TAB, tab);
  },
  removeBettingTab({ commit }) {
    commit(mutationTypes.REMOVE_BETTING_TAB);
  },
  setDeviceLayout({ commit }) {
    const isMobileDeviceLayout = isMobile().phone;
    const isTabletDeviceLayout = isMobile().tablet;
    const isDesktopDeviceLayout = !isMobile().phone && !isMobile().tablet;
    commit(mutationTypes.SET_IS_MOBILE_DEVICE_LAYOUT, isMobileDeviceLayout);
    commit(mutationTypes.SET_IS_TABLET_DEVICE_LAYOUT, isTabletDeviceLayout);
    commit(mutationTypes.SET_IS_DESKTOP_DEVICE_LAYOUT, isDesktopDeviceLayout);
  },
  formatPlayerTickets(state, payload) {
    return payload.map((ticket) => {
      let bets = [];
      bets = ticket.bets.map((bet) => ({
        id: bet.id,
        status: bet.status,
        round: bet.eventId,
        market: `${bet.bet.title} ${bet.ballPositions}`,
        outcome: String(bet.outcome.title),
        drawn: bet.outcome.drawn,
        //  eventValue: bet.outcome.drawn ?? [],
        value: bet.value,
        stake: bet.amount,
        type: bet.type,
        odd: Number(bet.odd),
        eventValue: getEventValue(bet),
      }));

      return {
        id: ticket.id,
        payout: ticket.payout,
        payin: ticket.payin,
        payinTax: ticket.payinTax,
        payoutTax: ticket.payoutTax,
        superBonus: ticket.superBonus,
        createdAt: ticket.createdAt,
        status: ticket.status,
        maxPossibleWin: ticket.maxPossibleWin,
        bets,
      };
    });
  },
  setFullscreenStatus({ commit }, fullScreen) {
    commit(mutationTypes.SET_FULL_SCREEN_STATUS, fullScreen);
  },
  disableAllBetting({ commit }, isDisabled) {
    commit(mutationTypes.ALL_BETTING_DISABLED, isDisabled);
  },
  setShowTicketCanceledModal({ commit }, show) {
    commit(mutationTypes.SET_SHOW_TICKET_CANCELED_MODAL, show);
  },
  setCanceledTicket({ commit }, ticket) {
    commit(mutationTypes.SET_CANCELED_TICKET, ticket);
  },
  setPayinDisabledFromSocket({ commit }, isDisabled) {
    commit(mutationTypes.SET_PAYIN_DISABLED_FROM_SOCKET, isDisabled);
  },
  setLastSelectedBet({ commit }, bet) {
    commit(mutationTypes.SET_LAST_SELECTED_BET, bet);
  },
  setHideLoader({ commit }, hide) {
    commit(mutationTypes.SET_HIDE_LOADER, hide);
  },
  async getPlayer({ getters, dispatch }, { config, token }) {
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      const userData = {
        auth: {
          token: player.auth.token,
          tpToken: player.auth.thirdPartyToken || null,
        },
        profile: merge({
          balance: player.balance,
          balanceTotal: player.balance,
          logged: isLoggedIn,
          id: player.uuid,
        }),
      };
      if (isLoggedIn) {
        dispatch('user/updateUser', userData);
        dispatch('setUserBalance', player.balance);
      }
    } catch (error) {
      const message = {
        message: getters.translations.general_player_login,
        notificationTimeout: 7,
      };
      dispatch('notifications/setNotification', message);
    }
  },
  setDrawnBallsStateActive({ commit }, isActive) {
    commit(mutationTypes.SET_DRAWN_BALLS_STATE_ACTIVE, isActive);
  },
  setTicketPayinInProg({ commit }, isInProg) {
    commit(mutationTypes.SET_TICKET_PAYIN_IN_PROG, isInProg);
  },
};
